import React from 'react'
import './Header.scss'
import { motion } from 'framer-motion'
import { images } from '../../constants'
import { AppWrap } from '../../wrapper'

function Header() {
    const scaleVariants = {
        whileInView: {
            scale: [0, 1],
            opacity: [0, 1],
            transition: {
                duration: 1,
                ease: 'easeInOut'
            }
        }
    }
    return (
        <div className='app__header app__flex'>
            <motion.div
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className='app__header-info'
            >
                <div className='app__header-badge'>
                    <div className='badge-cmp app__flex'>
                        <span>🖐️</span>
                        <div style={{ marginLeft: 20 }}>
                            <p className='p-text'>Hello I am</p>
                            <h1 className='head-text'>Rawllings</h1>
                        </div>
                    </div>
                    <div className='tag-cmp app__flex'>
                        <p className='p-text'>Web3 Developer</p>
                        <p className='p-text'>Ethereum</p>
                    </div>
                </div>
            </motion.div>
            <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className='app__header-img'
            >
                <img src={images.profile} alt='profile image' />
                <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    src={images.circle}
                    className="overlay_circle"
                    alt='img bg'
                />

            </motion.div>
            <motion.div
                variants={scaleVariants}
                whileInView={scaleVariants.whileInView}
                className="app__header-circles"
            >
                {[images.flutter, images.python, images.javascript, images.react].map(
                    (circle, index) => {
                        return <div className='circle-cmp app__flex' key={`circle${index}`}>
                            <img src={circle} />
                        </div>
                    }
                )}
            </motion.div>

        </div>
    )
}

export default AppWrap(Header, 'home')