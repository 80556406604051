import React from 'react'
import { BsTwitter, BsInstagram, BsLinkedin, BsGithub } from 'react-icons/bs'
//import { FaFacebook } from '../react-icons/fa'

function SocialMedia() {
    return (
        <div className='app__social'>
            <div>
                <a href='https://twitter.com/asana82040219'> <BsTwitter /></a>
            </div>
            <div>
                <a href='https://github.com/Jesuskid'><BsGithub /></a>
            </div>
            <div>
                <a href='https://www.linkedin.com/in/delalie-rawllings-tabot-b-h-593b87166/'><BsLinkedin /></a>
            </div>
        </div>
    )
}

export default SocialMedia