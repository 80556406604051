import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { images } from '../../constants'
import { urlFor, client } from '../../client'
import { AppWrap } from '../../wrapper'
import { MotionWrap } from '../../wrapper'
import ReactTooltip from 'react-tooltip'
import './Skills.scss'
const Skills = () => {
    const [skills, setSkills] = useState([])
    const [experience, setExpereinces] = useState([])

    useEffect(() => {
        const query = '*[_type == "skills"]'
        const expQuery = '*[_type == "experiences"]'
        client.fetch(query).then((data) => {
            setSkills(data)
            console.log(data)
        })

        client.fetch(expQuery).then((data) => {
            setExpereinces(data)
            console.log(data[0].works)

        })


    }, [])
    return (
        <>
            <h2 className='head-text'>Skills and Experience</h2>
            <div className='app__skills-container'>
                <motion.div
                    className='app__skills-list'
                >
                    {skills.map((skill) => (
                        <motion.div
                            whileInView={{ opacity: [0, 1] }}
                            transition={{ duration: 0.5 }}
                            className='app__skills-item app__flex'
                            key={skill.name}

                        >
                            <div className='app__flex' style={{ backgroundColor: skill.bgColor }}>
                                <img src={urlFor(skill.icon)} alt={skill.name} /><br />

                            </div>
                            <p className='p-text'>{skill.name}</p>
                        </motion.div>

                    ))}
                </motion.div>
                <motion.div className='app__skills-exp'>
                    {experience?.map((experi) => (

                        <motion.div
                            className='app__skills-exp-item'
                            key={experi.year}
                        >
                            <div className='app__skills-exp-year'>
                                <p className='bolt-text'>{experi.year}</p>
                            </div>
                            <motion.div className='app__skills-exp-works'>
                                {experi.works.map((exp, index) => (
                                    <>
                                        <motion.div
                                            whileInView={{ opacity: [0, 1] }}
                                            transition={{ duration: 0.5 }}
                                            className='app__skills-exp-work'
                                            data-tip
                                            data-for={exp.name}
                                            key={exp.name}
                                        >
                                            <h4 className='bold-text'>{exp.name}</h4>
                                            <p className='p-text'>{exp.company}</p>
                                        </motion.div>
                                        <ReactTooltip
                                            effect='solid'
                                            arrowColor='#fff'
                                            class='skills-tooltip'
                                            id={exp.name}
                                            key={index}
                                        >
                                            {exp.desc}
                                        </ReactTooltip>
                                    </>
                                ))}
                            </motion.div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </>
    )
}

export default AppWrap(
    MotionWrap(Skills, 'app__skills'),
    'skills',
    'app__whitebg'
)